import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Header } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import renderAst from "../components/render-markdown"

const cookiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/cookies/cookies/" }
      ) {
        htmlAst
        frontmatter {
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Cookies - SixEye"
        description="How the SixEye site uses cookies."
      />
      <Header as="h1" textAlign="center">
        {markdownData.frontmatter.title}
      </Header>
      <Container text>{renderAst(markdownData.htmlAst)}</Container>
    </Layout>
  )
}
export default cookiesPage;
