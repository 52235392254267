import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import CookieConsent from "./cookie-consent"
import Footer from "./footer"
import MainMenu from "./menu"

import withMobileLayout from "./with-mobile-layout"

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const LayoutDesktop = ({ children }) => (
  <>
    <MainMenu fixed="top" />
    <CookieConsent />
    <StyledFlexDiv>
      <main style={{ paddingTop: "7em" }}>{children}</main>
      <Footer />
    </StyledFlexDiv>
  </>
)

LayoutDesktop.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutDesktopWithMobileLayout = withMobileLayout(LayoutDesktop)

export { LayoutDesktopWithMobileLayout as default, LayoutDesktop }
